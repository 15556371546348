import { FirebaseOptions, initializeApp } from 'firebase/app';
import { Auth, FacebookAuthProvider, getAuth, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { auth } from 'firebaseui';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import getConfigFile from '../../../configFiles';
import 'firebaseui/dist/firebaseui.css';

type FirebaseUIAuthType = {
  uiCallback: (widget: unknown) => void;
  fbSignIn: (response: unknown) => void;
};

function FirebaseUIAuth({ uiCallback, fbSignIn }: FirebaseUIAuthType) {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [firebaseAuth, setFirebaseAuth] = useState<Auth | null>(null);

  useEffect(() => {
    getConfigFile('firebase').then((file) => {
      const firebaseApp = initializeApp(file as FirebaseOptions);
      setFirebaseAuth(getAuth(firebaseApp));
    });
  });

  // Configure FirebaseUI.
  const uiConfig = useMemo(
    () => ({
      // Popup sign in flow rather than redirect flow.
      signInFlow: 'popup',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
          scopes: ['email'],
        },
        {
          provider: FacebookAuthProvider.PROVIDER_ID,
          scopes: ['email'],
        },
        {
          provider: 'apple.com',
          scopes: ['email'],
        },
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: (response: unknown) => {
          fbSignIn(response);
          return true;
        },
      },
    }),
    [fbSignIn]
  );

  useEffect(() => {
    if (firebaseAuth === null) {
      return;
    }

    // Get or Create a firebaseUI instance.
    const firebaseUiWidget = auth.AuthUI.getInstance() || new auth.AuthUI(firebaseAuth);

    if (uiConfig.signInFlow === 'popup') {
      firebaseUiWidget.reset();
    }

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && userSignedIn) {
        firebaseUiWidget.reset();
      }
      setUserSignedIn(!!user);
    });

    // Trigger the callback if any was set.
    if (uiCallback) {
      uiCallback(firebaseUiWidget);
    }

    // Render the firebaseUi Widget.
    if (elementRef.current) {
      firebaseUiWidget.start(elementRef.current, uiConfig);
    }

    unregisterAuthObserver();
    firebaseUiWidget.reset();
  }, [userSignedIn, uiCallback, uiConfig, firebaseAuth]);

  return <div ref={elementRef} />;
}

export default FirebaseUIAuth;
