import { JSONValue } from "../../utils/types";

const PROD = "prod";
const DEV = "dev";

let host: string;
if (process.env.NODE_ENV === "development" && process.env.REACT_APP_HOST) {
  const hostName = process.env.REACT_APP_HOST;
  [, host] = hostName.split("://");
} else {
  host = window.location.hostname;
}

let env = PROD;
if (host.includes("localhost")) {
  env = DEV;
} else {
  env = ["dev", "qa", "staging"].find((e) => host.includes(`.${e}.`)) || PROD;
}

export default async function getConfigFile(file: string) {
  try {
    const { default: data } = await import(`./${env}/${file}.json`);
    return data as JSONValue;
  } catch (e) {
    console.log("Failed to load config file", e);
    return {};
  }
}
