import { Session, useSessionId } from "@seluxit/wappsto-porcelain";
import { useCallback, useEffect, useRef, useState } from "react";
import { getHost } from "../utils/helpers";

type SessionMessage = {
  sessionID: string;
};

const useMessage = () => {
  const [sessionID, setSessionID] = useState("");
  const session = useSessionId();
  const fallbackTimer = useRef<ReturnType<typeof setTimeout>>();
  const querySessionTimer = useRef<ReturnType<typeof setTimeout>>();

  const eventHandler = useCallback((event: MessageEvent<SessionMessage>) => {
    if (event.data.sessionID) {
      Session.setSessionID(event.data.sessionID);
      Session.validate().then(() => {
        setSessionID(event.data.sessionID);
      });
      window.removeEventListener("message", eventHandler);
      if (fallbackTimer.current) {
        clearTimeout(fallbackTimer.current);
      }
      if (querySessionTimer.current) {
        clearTimeout(querySessionTimer.current);
      }
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const session = queryParams.get("sessionID");

    if (session) {
      // Do not set the session before validating and
      // persisting it in wappsto-porcelain
      clearTimeout(querySessionTimer.current);
      querySessionTimer.current = setTimeout(() => {
        eventHandler({
          data: { sessionID: session },
        } as MessageEvent<SessionMessage>);
      }, 500);
    }
  }, [eventHandler]);

  useEffect(() => {
    if (window.opener) {
      window.removeEventListener("message", eventHandler);
      window.addEventListener("message", eventHandler);

      if (session) {
        clearTimeout(fallbackTimer.current);
        fallbackTimer.current = setTimeout(() => {
          eventHandler({
            data: { sessionID: session },
          } as MessageEvent<SessionMessage>);
        }, 500);
      }

      const host = getHost();
      [host, host.replace("//wapp.", "//")].forEach((origin) => {
        (window.opener as Window).postMessage(
          {
            ready: true,
          },
          origin
        );
      });
    }
  }, [eventHandler, session]);

  useEffect(() => {
    if (!window.opener && session) {
      setSessionID(session);
    }
  }, [session]);

  return {
    sessionID,
  };
};

export default useMessage;
