import { Installation } from "@seluxit/wappsto-porcelain";
import { useEffect } from "react";

const useInstallation = (
  installationID?: string | null,
  sessionID?: string
) => {
  const {
    sendAsync: getInstallation,
    item: installation,
    error,
  } = Installation.useRetrieve();

  useEffect(() => {
    if (sessionID && !installation && installationID && !error) {
      getInstallation(installationID);
    }
  }, [getInstallation, installationID, installation, sessionID, error]);

  return {
    installation,
    error,
  };
};

export default useInstallation;
