import { useCallback, useEffect, useMemo } from "react";
import { makeId } from "../utils/helpers";

const useHistoryReplace = () => {
  const identifier = useMemo(() => {
    return makeId();
  }, []);

  const runner = useMemo(() => {
    return `${window.location.protocol}//${window.location.host.replace(".wapp.", ".runner.").replace(":3050", ":4000")}`;
  }, []);

  const eventHandler = useCallback(
    (
      event: MessageEvent<{
        identifier: string;
        path: string;
        sameDocument: boolean;
      }>
    ) => {
      if (event.origin !== runner || event.data.identifier !== identifier) {
        return;
      }

      if (!event.data.sameDocument) {
        window.location.href = event.data.path;
        return;
      }

      try {
        window.history.replaceState(null, "", event.data.path);
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      }
    },
    [identifier, runner]
  );

  useEffect(() => {
    window.addEventListener("message", eventHandler);
    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, [eventHandler]);

  return { identifier };
};

export default useHistoryReplace;
