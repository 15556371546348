import { Card, Flex, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import InstallationDeleted from '../components/InstallationDeleted';
import InstallationMissing from '../components/InstallationMissing';
import NativeApplication from '../components/NativeApplication';
import RunnerFrame from '../components/RunnerFrame';
import useHistoryReplace from '../hooks/useHistoryReplace';
import useInstallation from '../hooks/useInstallation';
import useMessage from '../hooks/useMessage';
import { getIDfromHost } from '../utils/helpers';

const boxStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  borderRadius: 6,
  border: '1px solid #40a9ff',
};

const Main = () => {
  const { sessionID } = useMessage();
  const id = getIDfromHost();
  const { identifier } = useHistoryReplace();
  const { installation, error } = useInstallation(id, sessionID);

  const [installationDeleted, setInstallationDeleted] = useState(false);

  useEffect(() => {
    if (installation) {
      document.title = installation.meta.name_by_user || 'Wappsto Wapp';
    }
  }, [installation]);

  if (!sessionID) {
    return <Spin />;
  }

  if (error || !id || installationDeleted || installation?.native) {
    return (
      <Flex style={boxStyle} justify={'center'} align={'center'}>
        <Card bordered={false}>
          <Typography.Title>
            {error || !id ? <InstallationMissing /> : null}
            {installation?.native ? <NativeApplication /> : null}
            {installationDeleted ? <InstallationDeleted /> : null}
          </Typography.Title>
        </Card>
      </Flex>
    );
  }

  return (
    <>
      <Header installation={installation} onInstallationDeleted={setInstallationDeleted} />
      <RunnerFrame
        id={id}
        token={installation?.token_installation}
        session={installation?.session}
        identifier={identifier}
      />
    </>
  );
};

export default Main;
