import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from '../translations';

type RunnerFrameType = {
  id?: string;
  session?: string;
  token?: string;
  identifier?: string;
};

const RunnerFrame = ({ id, session, token, identifier }: RunnerFrameType) => {
  const [showLoading, setShowLoading] = useState(true);
  const frame = useRef<HTMLIFrameElement>(null);
  const { tC } = useTranslation();

  const url = useMemo(() => {
    let host = window.location.hostname.replace('.wapp.', '.runner.');
    if (window.location.port !== '' && window.location.port !== '80' && window.location.port !== '443') {
      host = `${host}:4000`;
    }
    return `${window.location.protocol}//${host}`;
  }, []);
  const path = window.location.pathname;
  const query = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    query.delete('sessionID');
    query.delete('tokenID');
    return query.toString();
  }, []);

  if (!id || !session) {
    return null;
  }

  return (
    <>
      {showLoading ? (
        <div key="wappLoading" className="loading-container">
          <span className="loader"></span>
          <span className="loading-message">{tC('runner.loading')}</span>
        </div>
      ) : null}
      <iframe
        ref={frame}
        src={`${url}/special_wappsto_launcher.html?tokenID=${token}&sessionID=${session}&path=${path}&query=${query}&identifier=${identifier}`}
        title="Wappsto Wapp Runner"
        allow="geolocation; microphone; camera; fullscreen"
        //sandbox="allow-same-origin allow-scripts"
        id="appFrame"
        key="wappRunner"
        className="app-runner-frame"
        onLoad={(event) => {
          if (showLoading) {
            setShowLoading(false);
          } else if (event.timeStamp > 10000) {
            window.location.reload();
          }
        }}
        onError={(ev) => console.error('Runner Error:', ev)}
      ></iframe>
    </>
  );
};

export default RunnerFrame;
